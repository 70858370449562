footer{
    background: var(--color-primary);
    padding: 3 rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 4rem;
}

footer a{

    color: var(--color-bg);
}

.footer_logo{

    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}

.permalinks{
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;

}

.footer_socials{
    display: flex;
    
    justify-content: center;
    gap: 1rem;
   margin-bottom: 4rem;
    
}

.footer_socials a{
background: var(--color-bg);
color: var(--color-white);
border-radius: 0.7rem;
padding: 0.8rem;
display: flex;
border: 1px solid transparent;


}

.footer_socials a:hover{
background: transparent;
color: var(--color-bg);
border-color: var(--color-bg);

}

.footer_copyright{
    margin-bottom: 2rem;
    color: var(--color-bg)l
}



   @media screen and (max-width:600px){
      
     .permalinks{
        flex-direction: column;
        gap: 1.5rem;
     }


     .footer_socials{
        margin-bottom: 2.6rem;
     }

    }
   
   