@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,600;1,300;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300;1,500;1,600&family=Roboto:ital,wght@1,100&display=swap');

*{

margin: 0;
padding: 0;
border: 0;
outline: 0;
box-sizing: border-box;
list-style: none;
text-decoration: none;


}


:root{
--color-bg: #1f1f38;
--color-bg-variant: #2c2c6c;
--color-primary: #4db5ff;
--color-white: #fff;
--color-light: rgba(255, 255, 255, 0.6);
--transition: all 400ms ease;

--container-width-lg: 75%;
--container-width-md:86%;
--container-width-sm:90%;

}

html{
   scroll-behavior: smooth;
}

::-webkit-scrollbar{
display: none;
}

body{

   font-family: "Roboto", sans-serif;
   background: var(--color-bg);
   color:var(--color-white);
   line-height: 1.7;
   

}

.container{

   width: var(--container-width-lg);
   margin: 0 auto;

}



h5{
text-align: center;
   font-size: 1rem;
}

h1{
   font-size: 2.5rem;
}
h2{

   color: var(--color-primary);
}
section{
margin-top:8rem;
}

section > h2{

   text-align: center;
   color: var(--color-primary);
}

section > h5{
color: var(--color-primary);
margin-bottom: 3 rem;


}

.text-light{
color: var(--color-light);
}






a  {
   color: var(--color-primary);
   transition: var(--transition);



}

a:hover{
color: var(--color-white);


}

.btn{
width: max-content;
display: inline-block;
color: var(--color-primary);
padding: 0.75rem 1.2rem;
border-radius: 0.4rem;
cursor: pointer;
border: 1px solid var(--color-primary);
transition: var(--transiton);

}


.btn:hover{
   background: var(--color-white);
   color: var(--color-bg);
   border-color: transparent;
}


.btn-primary{

   background: var(--color-primary);
   color: var(--color-bg);
}


img{
width: 100%;
height: 120%;
object-fit: cover;

}

@media screen and (max-width:1200px){
.container{
   width: var(--container-width-md);
}

section {
   margin-top:6rem;

}

}


@media screen and (max-width:600px){
   .container{
      width: var(--container-width-sm);
   }
    
   section > h2{
      margin-top:2 rem;
   
   }
   
   }
   
