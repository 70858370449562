.skill_container {
display: grid;
grid-template-columns: 1fr 1fr;
gap:2rem;
}

.skill_container > div{
background: var(--color-bg-variant);
padding: 2.4rem 5rem;
border-radius: 2rem;
border: 1px solid transparent;
transition: var(--transition);

}

.skill_container >div:hover{
background: transparent;
border-color:var(--color-primary-variant);
cursor: default;

}

.skill_container >div h3{
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);

}

.experience_content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}

.experience_detail {
    display: flex;
    gap: 1rem; 


    
}

@media screen and (max-width:1024px){
    .skill_container{
        grid-template-columns: 1fr;
    }

    .skill_container >div{
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience_content{
        padding: 1rem;
    }

}


   @media screen and (max-width:600px){
      
     
    .skill_container{
        gap:1rem;
    }

    .skill_container > div{

        width: 100%;
        padding: 2rem 1rem;

    }


    }
   
   