@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,600;1,300;1,500;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300;1,500;1,600&family=Roboto:ital,wght@1,100&display=swap);
header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;

}
.header_container{

    text-align: center;
    height: 100%;
    position: relative;
}




/* Court to Action */



.cta {

    margin-top: 1.5rem;
    display:flex;
    grid-gap: 1.2rem;
    gap: 1.2rem;
    justify-content: center;
}



.header_socials {

    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header_socials::after{
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}


/* Images */


.ME {

    background: linear-gradient(var(--color-primary), transparent);
    width:22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 6rem 1.5rem 1.5rem 1.5rem;

}

.scroll_down{
position:absolute;
right: -2.3rem;
bottom: 5rem;
-webkit-transform: rotate(90deg);
        transform: rotate(90deg);
font-weight: 300;
font-size: 0.9rem;

}
@media screen and (max-width:1048px){
 header{

    height: 100vh;
 }
 }

@media screen and (max-width:600px){
   
   header{
    height:100vh;
    }


.header_socials,.scroll_down {
    display: none;
}
} 




nav{
background: rgba(9, 0, 0, 0.3);
width: -webkit-max-content;
width: max-content;
display: block;
padding: 0.7rem 1.7rem;
z-index:2 ;
position: fixed;
left:50%;
-webkit-transform: translateX(-50%);
        transform: translateX(-50%);
bottom: 2rem;
display: flex;
grid-gap: 0.8rem;
gap: 0.8rem;
border-radius: 3rem;
-webkit-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);



}

nav a{
    background: transparent;
    padding: 0.9rem;
    border-radius:  50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;


}

nav a:hover {

    background: rgba(0, 0, 0, 0.3);
}

nav a.active {

    background: var(--color-primary);
    color: var(--color-bg) ;
}


.about_container{
    display: grid;
    grid-template-columns: 45% 50%;
    grid-gap: 15%;
    gap: 15%;
}


.about_me{
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45 deg,
    transparent,
    var(--color-primary),
    transparent
    );
    display: grid;
    place-items: center;
}

.about_me-image {
    border-radius: 2rem;
    overflow: hidden;
    -webkit-transform: rotate(7deg);
            transform: rotate(7deg);
    transition: var(--trasition) ;
}

.about_me-image:hover{

    -webkit-transform: rotate(0);

            transform: rotate(0);
}

.about_cards{

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
    gap: 1.5rem;

}

.about_card{

    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding:2rem;
    text-align: center;
    transition: var(--trasition);
}

.about_card:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
}

.about_icon {

    color: var (--color-primary);
    font-size:1.4rem;
    margin-bottom: 1rem;

}

.about_card h5{
    font-size: 0.95rem;
}

.about_card small{
font-size: 0.7rem;
color: var(--color-light);
}

.about_content p{
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
}

@media screen and (max-width:1024px){
    .about_container{
        grid-template-columns: 1fr;
        grid-gap:0;
        gap:0;
    }
    
   
    .about_me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about_content p{

        margin: 1rem 0 1.5rem;

    }

}


   @media screen and (max-width:600px){
      
     .about_me{

        width: 65%;
        margin: 0 auto 3rem;
     }


     .about_card{
        grid-template-columns: 1fr 1fr;
        grid-gap:1rem;
        gap:1rem;

     }


     .about_content{
        text-align: center;

     }

     .about_content p{
        margin:1
     }


    }
   
   
.skill_container {
display: grid;
grid-template-columns: 1fr 1fr;
grid-gap:2rem;
gap:2rem;
}

.skill_container > div{
background: var(--color-bg-variant);
padding: 2.4rem 5rem;
border-radius: 2rem;
border: 1px solid transparent;
transition: var(--transition);

}

.skill_container >div:hover{
background: transparent;
border-color:var(--color-primary-variant);
cursor: default;

}

.skill_container >div h3{
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);

}

.experience_content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 2rem;
    row-gap: 2rem;
}

.experience_detail {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem; 


    
}

@media screen and (max-width:1024px){
    .skill_container{
        grid-template-columns: 1fr;
    }

    .skill_container >div{
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience_content{
        padding: 1rem;
    }

}


   @media screen and (max-width:600px){
      
     
    .skill_container{
        grid-gap:1rem;
        gap:1rem;
    }

    .skill_container > div{

        width: 100%;
        padding: 2rem 1rem;

    }


    }
   
   
.services_container{

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
    gap: 3rem;
}


.service{

    background: var(--color-bg-variant);
    border-radius: 0 0 2rem 2rem;
    border: 1px solid var(--color-primary);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    transition: var(--transition);

}

.service:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.service_head{
    background: var(--color-primary);
    padding: 2rem;
    border-radius: 0 0  2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}


.service_head h3{
    color: var(--color-bg);
    font-size: 1rem;
    text-align: center;
}


.service_list{
    padding: 2rem;

}


.service_list li{
    display: flex;
    grid-gap:1rem;
    gap:1rem;
    margin-bottom: 0.8rem;
}


.service_list-icon{
color: var(--color-primary);
margin-top: 2px;
}


.service_list p{
font-size: 0.9rem;


}

@media screen and (max-width:1024px){
    .services_container{

        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;
        gap: 2rem;
    }

    .service{
        height: auto;

    }

}


   @media screen and (max-width:600px){
      .services_container{

        grid-template-columns: 1fr;
        grid-gap:1.5rem;
        gap:1.5rem;
      }
    

    }
   
   
.portfolio_container{
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    grid-gap: 2.5rem;
    gap: 2.5rem;
}


.portfolio_item{
    color: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);

}


.portfolio_item:hover {
    border-color: var(--color-primary-variant);
    background:transparent;
}

.portfolio_item-image{
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio_item h3{
    margin: 1.2rem 0 2rem;
}

.portfololi_item-cta{

    display: flex;
    grid-gap:1rem;
    gap:1rem;
    margin-bottom: 1rem;
}


@media screen and (max-width:1024px){
    .portfolio_container{
        grid-template-columns: 1fr 1fr;
        grid-gap:1.2rem;
        gap:1.2rem
    }

}


   @media screen and (max-width:600px){
      
     .portfolio_container{
        grid-template-columns: 1fr;
        grid-gap: 1rem;
        gap: 1rem;
     }


    }
   
   

.container.contact_container{

    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    grid-gap: 12%;
    gap: 12%;
}

.contact_options{

    display: flex;
    flex-direction: column;
    grid-gap: 1.2rem;
    gap: 1.2rem;
}

.contact_option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);

}

.contact_option:hover{
background: transparent;
border-color: var(--color-primary-variant);

}
.contact_option-icon{

    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact_option a{

    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

form{
    display: flex;
    flex-direction: column;
    grid-gap:1.2rem;
    gap:1.2rem;
}


input,textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 1.5rem;
    background: var(--color-bg-variant);
    border: 2px;
    resize: none;
    color: var(--color-white);
}

@media screen and (max-width:1024px){
   .container.contact_container {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    gap: 2rem;
   }

}


   @media screen and (max-width:600px){
    .container.contact_container {
        width: var(--container-width-sm);
       }



    }
   
   
footer{
    background: var(--color-primary);
    padding: 3 rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 4rem;
}

footer a{

    color: var(--color-bg);
}

.footer_logo{

    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}

.permalinks{
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
    margin: 0 auto 3rem;

}

.footer_socials{
    display: flex;
    
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
   margin-bottom: 4rem;
    
}

.footer_socials a{
background: var(--color-bg);
color: var(--color-white);
border-radius: 0.7rem;
padding: 0.8rem;
display: flex;
border: 1px solid transparent;


}

.footer_socials a:hover{
background: transparent;
color: var(--color-bg);
border-color: var(--color-bg);

}

.footer_copyright{
    margin-bottom: 2rem;
    color: var(--color-bg)l
}



   @media screen and (max-width:600px){
      
     .permalinks{
        flex-direction: column;
        grid-gap: 1.5rem;
        gap: 1.5rem;
     }


     .footer_socials{
        margin-bottom: 2.6rem;
     }

    }
   
   
*{

margin: 0;
padding: 0;
border: 0;
outline: 0;
box-sizing: border-box;
list-style: none;
text-decoration: none;


}


:root{
--color-bg: #1f1f38;
--color-bg-variant: #2c2c6c;
--color-primary: #4db5ff;
--color-white: #fff;
--color-light: rgba(255, 255, 255, 0.6);
--transition: all 400ms ease;

--container-width-lg: 75%;
--container-width-md:86%;
--container-width-sm:90%;

}

html{
   scroll-behavior: smooth;
}

::-webkit-scrollbar{
display: none;
}

body{

   font-family: "Roboto", sans-serif;
   background: #1f1f38;
   background: var(--color-bg);
   color:#fff;
   color:var(--color-white);
   line-height: 1.7;
   

}

.container{

   width: 75%;

   width: var(--container-width-lg);
   margin: 0 auto;

}



h5{
text-align: center;
   font-size: 1rem;
}

h1{
   font-size: 2.5rem;
}
h2{

   color: #4db5ff;

   color: var(--color-primary);
}
section{
margin-top:8rem;
}

section > h2{

   text-align: center;
   color: #4db5ff;
   color: var(--color-primary);
}

section > h5{
color: #4db5ff;
color: var(--color-primary);
margin-bottom: 3 rem;


}

.text-light{
color: rgba(255, 255, 255, 0.6);
color: var(--color-light);
}






a  {
   color: #4db5ff;
   color: var(--color-primary);
   transition: all 400ms ease;
   transition: var(--transition);



}

a:hover{
color: #fff;
color: var(--color-white);


}

.btn{
width: -webkit-max-content;
width: max-content;
display: inline-block;
color: #4db5ff;
color: var(--color-primary);
padding: 0.75rem 1.2rem;
border-radius: 0.4rem;
cursor: pointer;
border: 1px solid #4db5ff;
border: 1px solid var(--color-primary);
transition: var(--transiton);

}


.btn:hover{
   background: #fff;
   background: var(--color-white);
   color: #1f1f38;
   color: var(--color-bg);
   border-color: transparent;
}


.btn-primary{

   background: #4db5ff;

   background: var(--color-primary);
   color: #1f1f38;
   color: var(--color-bg);
}


img{
width: 100%;
height: 120%;
object-fit: cover;

}

@media screen and (max-width:1200px){
.container{
   width: 86%;
   width: var(--container-width-md);
}

section {
   margin-top:6rem;

}

}


@media screen and (max-width:600px){
   .container{
      width: 90%;
      width: var(--container-width-sm);
   }
    
   section > h2{
      margin-top:2 rem;
   
   }
   
   }
   

